import { Layout } from '@components/layout';
import { Link } from "gatsby";
import type { IPage, IPost, IProduct, ILocation } from '@standardTypes';
import React from 'react';
import { LangCtx, useContextState } from "@components/contexted"

interface BlogProps {
    pageContext: {
        page: IPage;
        pages: Array<IPage>;
        translationUrls: Object;
        posts: Array<IPost>
    };
    location: ILocation;
}

const BlogPage: React.FC<BlogProps> = ({ pageContext, location }) => {
    const { page, translationUrls, posts } = pageContext;
    const { language } = useContextState(LangCtx, ["language"])

    console.log('posts', posts)
    return (
        <Layout {...page} translationUrls={translationUrls}>
            {posts.map((post) => {
                return(
                    <Link to={`/${language}/blog/${post.slug}`}>{post.title}</Link>
                )
            })}
            <h1>blog</h1>
        </Layout>
    );
};

export default BlogPage;
